<template>
  <div>
    <b-card
      no-body
    >
      <b-card-body>

        <div class="d-flex justify-content-between flex-wrap">

          <!-- filter -->
          <b-form-group

            class="mb-0 gr-filter-search align-items-center"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('dic.typeToSearch')"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  {{ $t('dic.clear')}}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <div
            class="mb-md-0 align-items-center d-flex"
          >
            <b-form-group
              :label="$t('dic.sort')"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mb-md-0 align-items-center d-none d-md-flex"
            >

              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      {{ $t('dic.none') }}
                    </option>
                  </template>
                </b-form-select>

                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    {{ $t('dic.asc') }}
                  </option>
                  <option :value="true">
                    {{ $t('dic.desc') }}
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <div class="dropdownexport ml-1">
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  @click.prevent="exportDicAsCSV(true)"
                >
                  <feather-icon
                    icon="ExternalLinkIcon"
                    size="14"
                    class="dropicons align-middle"
                  />{{ $t('dic.exportAsCSV') }}
                </b-dropdown-item>
                <b-dropdown-item
                  @click.prevent="exportDicAsCSV(false)"
                >
                  <feather-icon
                    icon="ExternalLinkIcon"
                    size="14"
                    class="dropicons align-middle"
                  />{{ $t('dic.exportAsCSVNoheader') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </b-card-body>

      <p
        v-if="!seenTable"
        class="text-center mt-1"
      >
        <b-spinner
          class="mr-1"
          variant="secondary"
        />
      </p>

      <b-table
        v-if="seenTable"
        striped
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <template #cell(created)="data">
          {{ data.item.created | formatDate }}
        </template>

        <template #cell(actions)="data">
          <div>
            <b-dropdown
              class="m-md-0"
              variant="link"
              no-caret
              toggle-class="text-decoration-none"
            >
              <template
                v-slot:button-content
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item @click="editPhrase(data.item.id, data.item.text, data.item.translation)">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />{{ $t('dic.edit') }}
              </b-dropdown-item>

              <b-dropdown-divider />

              <b-dropdown-item @click="deletePhraseDialog(data.item.id, data.item.text)">
                <feather-icon
                  icon="DeleteIcon"
                  class="mr-50"
                />{{ $t('dic.delete') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>

      <b-card-body
        v-if="seenTable"
        class="d-flex justify-content-between flex-wrap pt-0"
      >

        <!-- page length -->
        <b-form-group
          :label="$t('dic.perPage')"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1 align-items-center"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

    </b-card>

    <b-modal
      id="modal-add-dictionary"
      ref="modalAddDictionary"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Cancel"
      centered
      :title="$t('dic.editTranslation')"
      :ok-disabled="noInput()"
      :close-on-backdrop="false"
      no-close-on-esc
      no-close-on-backdrop
      @ok="savePhrase"
    >
      <b-form>
        <b-form-group>
          <label for="phrase">{{ $t('dic.phrase') }}</label>
          <b-form-input
            id="phrase"
            v-model="currPhrase"
            type="text"
          />
        </b-form-group>
        <b-form-group>
          <label for="trans">{{ $t('dic.translation') }}</label>
          <b-form-input
            id="trans"
            v-model="currTranslation"
            type="text"
            :placeholder="$t('dic.enterTranslation')"
          />
        </b-form-group>
      </b-form>

    </b-modal>

    <b-modal
      ref="modal-form-delete-phrase"
      centered
      :ok-title="$t('confirm.delPhrase.ok')"
      :cancel-title="$t('confirm.cancel')"
      :title="$t('confirm.pleaseConfirm')"
      ok-variant="danger"
      @ok="deletePhrase"
    >
      <div class="d-block text-center">
        <h3>{{ $t('confirm.delPhrase.msg', { phrase: phraseToDeleteTitle }) }}</h3>
      </div>
    </b-modal>

  </div>

</template>

<script>
import {
  BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem,
  BDropdownDivider, BModal, BSpinner, BForm, BCard,
} from 'bootstrap-vue'

// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Ripple from 'vue-ripple-directive'
import { clearUserDatePartial } from '@/auth/utils'

export default {
  components: {
    BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BDropdownDivider, BModal, BSpinner, BForm, BCard,
  },
  directives: {
    // Ripple,
  },
  data() {
    return {
      sourceText: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      perPage: 100,
      pageOptions: [100, 500, 1000],
      totalRows: 1,
      currentPage: 1,
      // sortBy: '',
      sortBy: 'created',
      // sortDesc: false,
      sortDesc: true,
      // sortDirection: 'asc',
      sortDirection: 'asc',
      filter: null,
      filterOn: ['text', 'translation', 'language'],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'text', label: this.$t('dic.fields.phrase'), sortable: true },
        { key: 'translation', label: this.$t('dic.fields.translation'), sortable: true },
        { key: 'context', label: this.$t('dic.fields.context'), sortable: false },
        { key: 'language', label: this.$t('dic.fields.language'), sortable: true },
        { key: 'created', label: this.$t('dic.fields.created'), sortable: true },
        { key: 'actions', label: this.$t('dic.fields.actions') },
      ],
      /* eslint-disable global-require */
      //   items: [
      //     {
      //       text: 'A book',
      //       translation: 'Книга',
      //       context: 'Context',
      //       language: 'EN-UK',
      //       created: '01.01.2022 12:12:12',
      //     },
      //     {
      //       text: 'A book 2',
      //       translation: 'Книга',
      //       context: 'Context',
      //       language: 'EN-UK',
      //       created: '01.01.2022 12:12:12',
      //     },
      //   ],
      items: [],
      /* eslint-disable global-require */
      seenTable: true,
      phraseToDeleteTitle: '',
      phraseToDeleteId: 0,
      currId: null,
      currPhrase: null,
      currTranslation: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },

  created() {
    //
  },
  mounted() {
    this.loadPhrases()
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      // localStorage.removeItem('userData')
      clearUserDatePartial()
      this.$router.push({ name: 'auth-login', params: { needToRefreshPage: true } })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    // remove \n \r, remove '.' and ',' at the begin and and of line
    ClearStr(s) {
      return s.replace(/\n|\r|<br>/g, '').trim().replace(/^[.,]*/, '').replace(/[.,]*$/, '')
        .trim()
    },

    noInput() {
      if ((!this.currPhrase) || (!this.currTranslation)) {
        return true
      }
      return !((this.ClearStr(this.currPhrase).length > 0) && (this.ClearStr(this.currTranslation).length > 0))
    },

    loadPhrases() {
      this.seenTable = false
      useJwt.getPhrases({
      })
        .then(response => {
          const resData = response.data
          this.items = resData
          // Set the initial number of items
          this.totalRows = this.items.length
        })
        .catch(error => {
          if (error.status === 401) {
            this.logout()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error getting translations',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.email,
              },
            })
          }
        })
        .finally(() => {
          this.seenTable = true
        })
    },

    deletePhraseDialog(id, text) {
      this.phraseToDeleteTitle = text
      this.phraseToDeleteId = id
      this.$refs['modal-form-delete-phrase'].show()
    },

    deletePhrase() {
      this.seenTable = false
      useJwt.deletePhrase(
        {
          id: this.phraseToDeleteId,
        },
      )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          const resData = response.data
          if (resData.success) {
            this.loadPhrases()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'The translation is deleted',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: resData.detail,
              },
            })
          } else {
            this.$({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Can\'t delete the translation',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: resData.detail,
              },
            })
          }
        })
        .catch(error => {
          if (error.status === 401) {
            this.logout()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error deleting the phrase',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.email,
              },
            })
          }
        })
        .finally(() => {
          this.seenTable = true
        })
    },

    editPhrase(id, phrase, translation) {
      this.currId = id
      this.currPhrase = phrase
      this.currTranslation = translation
      this.$refs.modalAddDictionary.show()
    },

    savePhrase() {
      useJwt.updatePhrase(
        {
          id: this.currId,
          text: this.ClearStr(this.currPhrase),
          translation: this.ClearStr(this.currTranslation),
        },
      )
        .then(response => {
          const resData = response.data
          if (resData.success) {
            this.loadPhrases()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'The translation is updated',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: resData.detail,
              },
            })
          } else {
            this.$({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Can\'t update the translation',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: resData.detail,
              },
            })
          }
        })
        .catch(error => {
          if (error.status === 401) {
            this.logout()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error updateing the translation',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.detail,
              },
            })
          }
        })
        .finally(() => {
        })
    },
    exportDicAsCSV(withHeaders) {
      const refinedData = []
      if (withHeaders) {
        const titleKeys = Object.keys(this.items[0])
        refinedData.push(titleKeys)
      }

      this.items.forEach(item => {
        let itemArr = Object.values(item)
        itemArr = itemArr.map(i => (i ? i.toString().replaceAll(';', ',') : ''))
        refinedData.push(itemArr)
      })
      let csvContent = ''
      refinedData.forEach(row => {
        csvContent += `${row.join(';')}\r\n`
      })

      const currentdate = new Date()
      const filename = `Dictionary-${currentdate.getFullYear()}-${currentdate.getMonth() + 1}-${currentdate.getDate()}.csv`
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename)
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', filename)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    },
  },
}
</script>

<style>
  .aslink {
    cursor: pointer;
    color: #7367f0;
  }
  .gr-filter-search button {
    margin: 0;
  }

  .modal-dialog-scrollable.modal-dialog{
    overflow-y: initial !important
  }
  .modal-dialog-scrollable .modal-body{
      height: 450px;
      overflow-y: auto;
  }
  .dropicons {
    margin-right: 3px;
  }

</style>
